.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.App {
  max-width: 1000px;
  min-width: 500px;
  margin: 10px;
  height: 95%;
  color: #ffffff;
}

.card-container{
  height: 100px;
  border: 1px rgb(255 255 255 / 17%) solid;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;  
  background: rgb(43 65 83 / 16%);
  padding: 20px;
}

.progress-span {
  font-size: 24px;
}

.card-details{

}

.media-section-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 100px;
}

.media-section-header {
  display: flex;
  flex-direction: row;
}

.media-section-title {
  font-size: 48px;
  font-weight: 500;
}

.media-list {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.media-icon {
  display: flex;
  align-items:baseline
}

.media-label {
  display: flex;
  flex-direction: row;
  padding: 10px;
  gap:10px;
}
